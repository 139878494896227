
















































import { defineComponent, reactive } from '@vue/composition-api';
import { formatDate } from '../utils';

export default defineComponent({
  props: {
    data: {
      required: true,
      type: Array,
    },
  },
  setup(props) {
    const apiData = props.data;
    const dataSet = reactive([]);

    apiData.forEach((item, index) => {
      dataSet.push(item);
    });

    return {
      title: 'คำสั่ง/คำพิพากษา',
      dataSet,
      formatDate,
    };
  },
});
